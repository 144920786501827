<template>
    <div>
      <v-card>
        <v-card-title>Listado de Categorías</v-card-title>
        <v-card-text>
          <botonera 
            :botones="botones" 
            alinear="right"
            @crear="dialog = !dialog"
          ></botonera>
        </v-card-text>
      </v-card>
  
      <datatable 
        :cabeceras="listHeaders" 
        :items="listBody" 
        titulo="Listado de Categorías" 
        label="Escriba Para buscar Categorías" 
        icono="list" 
        color_icono="azul" 
        :tachar="false"
        :acciones="['editar','eliminar']"
        @editar="editCategoria($event)"
        @eliminar="deleteCategoria($event)">
      </datatable>
  
      <!-- Modal Nuevo Acopio -->
      <v-dialog
        v-model="dialog"
        width="800px"
        @click:outside = "closeModal"
      >
        <v-card>
          <v-card-title class="blue darken-1">{{ title }}</v-card-title>
          <v-card-text>
            <form>
              <v-row class="mx-2">
                <v-col
                  class="align-center justify-space-between"
                  cols="4"
                >
                  <v-text-field
                    prepend-icon="mdi-barcode"
                    placeholder="Nombre"
                    v-model="item.nombre"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
          <v-card-actions>
                <v-btn
                  @click="closeModal"
                >Cerrar</v-btn>
                <v-btn
                  color="primary"
                  @click="saveCategoria"
                >Grabar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- / Modal -->
  
      <!-- Modal upload -->
      <!-- <v-dialog
        v-model="upload"
        width="600px"
      >
        <v-card>
          <v-card-title class="green darken-1">
            Subir listado de acopios desde excel
          </v-card-title>
          <v-card-text>
            <v-row class="mx-2">
              <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="upload = false"
            >Cancelar</v-btn>
            <v-btn
              color="primary"
              @click="uploadAcopios"
            >Subir</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <!-- / Modal -->
      
      <v-snackbar
        v-model="snackbar"
        :timeout="5000"
        :color="color"
      >
        {{ validation_message }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  <script>
    import {mapState, mapMutations} from 'vuex'
    import DataTable from '@/components/base/DataTable.vue';
  
    export default {
      data: () => ({
        title: 'Nueva Categoría',
        color: 'green',
        snackbar: false,
        errors: [],
        validation_message: '',
        search: null,
        file: null,
        dialog: false,
        upload: false,
        changeState: false,
        empresas: [],
        colors: [],
        sizes: [],
        state: null,
        states: [],
        index: -1,
        item: {
          id: 0,
          nombre: ''
        },
        listHeaders: [
          {
            text: 'Id',
            align: 'center',
            sortable: true,
            value: 'id',
            class: "blue-grey darken-4 white-text"
          },
          {
            text: 'Nombre',
            align: 'center',
            sortable: true,
            value: 'nombre',
            class: "blue-grey darken-4 white-text"
          },
          {
            text: 'Acciones',
            align: 'center',
            sortable: false,
            value: 'actions',
            class: "blue-grey darken-4 white-text"
          },
        ],
        listBody: [],
        botones:[
          {boton:'crear',tooltip:'Nueva Categoría'},
          //{boton:'eliminar',tooltip:'Borrar Todo'}
        ]
      }),
      mounted() {
        this.locaCategorias();
      },
      methods:{
        async locaCategorias(){
          this.mostrarLoading('Cargando listado de áreas...')
          let url = `${this.base_url}categorias`;
          await this.axios.get(url, this.headers).then((response)=>{
            this.listBody = response.data;
            this.ocultarLoading()
          }).catch((error)=>{
              console.log(error);
              this.ocultarLoading()
          });
        },
        async saveCategoria(){
          // validaciones
          this.errors = [];
          if(!this.item.nombre){
            this.errors.push("Debe indicar nombre de la categoría");
          }
          if(this.errors.length > 0){
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
            return;
          }
          let url = `${this.base_url}categorias`;
          let data = {
            'nombre': this.item.nombre,
          };
          if(this.item.id > 0){
            url = url + "/" + this.item.id;
            await this.axios.put(url, data, this.headers).then((response)=>{
                let index = this.listBody.findIndex(i => i.id == this.item.id)
              console.log(response.data);
              this.listBody.splice(index, 1, response.data);
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
                this.showSnackBar(true);
            });
          }else{
            await this.axios.post(url, data, this.headers).then((response)=>{
                this.listBody.push(response.data);
                this.showSnackBar(false);
            }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
            });
          }
          this.cleanForm();
        },
        editCategoria(item) {
          //console.log('acopio editar',item);
  
          this.index = this.listBody.findIndex(a => a.id == item.id);
          this.item = Object.assign({}, item);
          this.dialog = true;
          this.title = "Editar Categoría";
        },
        async deleteCategoria(item) {
          this.$confirm('Desea borrar esta categoría?').then(res => {
            if(res){
              let url = `${this.base_url}categorias/${item.id}`;
              this.axios.delete(url, this.headers).then((response)=>{
                console.log(response);
                this.locaCategorias();
                this.showSnackBar(false);
              }).catch((error)=>{
                this.validation_message = error;
                this.showSnackBar(true);
              });
            }
          });
        },
        showSnackBar(error){
          if(error){
            this.color = 'red';
          }else{
            this.color = 'green';
            this.validation_message = "Ejecutado exitosamente!";
          }
          this.snackbar = true;
        },
        closeModal(){
          this.cleanForm();
          this.dialog = false;
          this.error = null;
          this.title = "Nueva Área";
        },
        cleanForm(){
          this.item.id = 0;
          this.item.nombre = "";
          this.dialog = false;
        },
        
        
        
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
      },
      computed:{
        ...mapState(['loading', 'base_url', 'headers']),
        
      },
      components:{
        'datatable': DataTable
      }
    }
  </script>